 .custom_input_container{
     margin-bottom: 5%;
 }
 .custom_input{
    padding: 1.5% 5%;
    font-size: 3.5rem;
    border: 1px solid #707070;
    width: 100%;
    border-radius: 6px !important;
    /* margin-bottom: 1rem; */

}

.custom_input::placeholder{
    color: #A79291;

}

.custom_input:focus{
    outline: none;
    border: 1px solid #707070;;
}

.custom_input_error{
    font-size: 3rem;
    color: red;
}