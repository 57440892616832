.home_header{
    /* display: flex;
    align-items: center; */
    margin-top: 3%;
    width: 100%;
    padding:3% 2%;
    font-family: myriad-arabic !important;
}

.home_header_content h2{
    color: var(--primary);
    font-size: 7.5rem;
}

.home_header_content p{
    color: var(--text_color);
    font-size: 5rem;
    line-height: 7rem;
    padding-block: 4% 5%;
}

.home_header_image img{
    max-width: 100%;
}

.home_header_content{
    margin-top: 3% !important;
    
}

@media only screen and (max-width: 767.9px) {
    .home_header{
        /* display: flex;
        align-items: center; */
        padding-top: 80%;
    }

    .home_header_content{
        margin-top: 0% !important;
        text-align: center;
    }

    .home_header_content h2{
        font-size: 6rem;
    }
    
    .home_header_content p{
        font-size: 4rem;
    }
    
  }

  
@media only screen and (max-width: 400px) {

    .home_header_content h2{
        font-size: 5.5rem;
    }
    
    .home_header_content p{
        font-size: 3.5rem;
    }
    .home_header{
        /* display: flex;
        align-items: center; */
        padding-top: 80%;
    }
    
  }