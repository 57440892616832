.app_download {
  padding: 5%;
  background-color: #fff;
  width: 100%;
  font-family: Arial;
}

.app_download_heading {
  color: var(--blue);
  font-size: 3.6rem;
  font-weight: bold;
  padding-bottom: 4%;
}
.app_download_content {
  padding-top: 5%;
}
.app_download .app_download_text {
  font-size: 3rem;
  color: #000;
}

.app_download img {
  max-width: 50%;
  width: 40%;
  cursor: pointer;
  /* display: block; */
}

.app_download_links{
  width: 100%;
  text-align: center;
  display: block;
}


@media only screen and (max-width: 767px){
  .app_download_content_wrapper{
    text-align: center;
  }
}
@media only screen and (max-width: 500px){
  .app_download {
    padding-block: 10%;
  }
  .app_download h2{
    padding-bottom: 5%;
  }
}
