@import url("https://use.typekit.net/gcd1tcd.css");

/* import all css files */
@import url("./Header.css");
@import url("./Home.css");
@import url("./Navbar.css");
@import url("./AppDownload.css");
@import url("./DiscoverFeatures.css");
@import url("./Heading.css");
@import url("./ContactUs.css");
@import url("./CustomInput.css");
@import url("./CustomButton.css");
@import url("./Footer.css");
@import url("./CustomModal.css");


/* Import Fonts */
@font-face{
  font-family: myriad-arabic;
  src: url("../assets/fonts/MYRIADPRO-BOLD.woff");
  src: url("../assets/fonts/MYRIADPRO-COND.woff");
  src: url("../assets/fonts/MyriadPro-Light.woff");
  src: url("../assets/fonts/MYRIADPRO-SEMIBOLD.woff");
  src: url("../assets/fonts/MYRIADPRO-REGULAR.woff");
}

@font-face{
  font-family: fontAwsome5Brands;
  src: url("../assets/fonts/fa-brands-400.ttf");
}


/* Colors   */
:root {
  --primary: #F78985;
  --secondary: #FFF5F3;
  --text_color: #100F0F;
  --blue: #A485F7;
}

/*  Scroll Bar  */
body::-webkit-scrollbar {
  width: 1em;
  border-radius: 1rem;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  outline: 1px solid slategrey;
  border-radius: 5rem;

}


*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p{
  line-height: 1.3 !important;
}

html{
  font-size: 40%;
  line-height: 1.3;
  scroll-behavior: smooth;
}

body{
  font-family: Arial;
  }
  

  .secondary_heading{
    font-size: 4.5rem;
    font-weight: bold;
    color: var(--primary);
    padding-bottom: 2rem;
  }

  .primary_text{
    color: #000;
    font-size: 4rem;
    line-height: 1.2;
  }

  @media only screen and (max-width: 1600px) {
    html{
      font-size: 30%;
    }
  }

  @media only screen and (max-width: 1366.9px) {
    html{
      font-size:30%;
    }
  }

  @media only screen and (max-width: 991.9px) {
    html{
      font-size:28%;
    }
  }

  @media only screen and (max-width: 767.9px) {
    html{
      font-size:27%;
    }
  }

  @media only screen and (max-width: 500.9px) {
    html{
      font-size:25%;
    }
  }

  @media only screen and (max-width: 420.9px) {
    html{
      font-size:20%;
    }
  }