.home{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.home_header_container{
    background-color: var(--secondary);
    padding-bottom: 20%;

    background: url("../assets/images/header.png"), var(--secondary);
    background-position: right -7% bottom 50%;
     background-size: 55% 75%;
     background-repeat: no-repeat;
}

@media only screen and (max-width: 991.9px) {
    .home_header_container{
         background-size: 60% 65%;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .home_header_container{
        background-position: right 0% top 20%;
        background-size: 70% 40%;
        /* padding-bottom: 100%; */
   }
  }

  @media only screen and (max-width: 600px) {
    .home_header_container{
      background-position: right 0% top 20%;
      background-size: 95% 45%;;
    padding-bottom: 10%
    
        /* padding-bottom: 100%; */
   }
  }

  @media only screen and (max-width: 400px) {
    .home_header_container{
      background-position: right 0% top 20%;
      background-size: 95% 50%;
    padding-bottom: 2%;

    
        /* padding-bottom: 100%; */
   }
  }