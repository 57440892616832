.custom_button{
    font-size: 5rem !important;
    background-color: var(--blue) !important;
    outline: none !important;
    border: none !important;
    padding: 1% 7% !important;
    border-radius: 10px !important;
}

.custom_button .spinner-border{
    width: 5rem !important;
height: 5rem !important;
}

@media only screen and (max-width: 767.9px) {
    .custom_button{
        font-size: 4rem !important;
    }
    
    
  }