.contactus{
    padding: 6% 4% 4% 4%;
    background-color: #fff;
    width: 100%;
}

.contactus h2{
    padding-bottom: 5%;
}
.contactus_section{
    padding-block: 5%;
    align-items: center;
}

.contactus_inputs_section{
    padding-left: 5% !important;
}


@media only screen and (max-width: 767.9px) {    
    .contactus_section{
        padding-inline: 8%;
    }

    .contactus_inputs_section {
        padding-left: 0% !important;
    }
    .contactus{
        padding: 12% 4% 4% 4%;
        background-color: #fff;
        width: 100%;
    }
  }