.custom_footer{
    background-color: var(--secondary);
    font-size: 3rem;
    /* font-size: 2.2rem; */
    color: var(--primary);
    font-family: fontAwsome5Brands !important;
}

.custom_footer_heading{
    text-align: center;
}

.custom_footer_copyrights{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 2%; */
    gap: 2.5%;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}


  
  .footer_app_download_links{
      display: inline-block;
      /* width: 50rem; */
    text-align: end;
   
  }

  .footer_app_download_links img {
    max-width: 50%;
    width: 20%;
    cursor: pointer;
    margin-right: 15%;
    /* display: block; */
  }
.footer_links_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-block: 5rem;
}
.footer_app_download_container{
    text-align: right;
    display: flex;
    flex-direction: column;
}
  /*      SOCIAL MEDIA LINKS    */
.home-social-links{
  display: flex;
  justify-content: center;
    align-items: center;
    gap: 1rem;
    /* padding-bottom: 8rem; */
   }

   .falcon_footer a{
       color: #f87873 !important;
       font-weight: bold;
       display: inline-block;
       text-decoration: none !important;
       
   }
   .home-social-link{
     /* margin-left: -0rem; */
     display: flex;
     background-color: var(--primary);
     padding: 1.5rem;
     border-radius: 50%;
     filter: drop-shadow(0 0 1rem rgba(0,0,0,0.3));
     color: #fff !important;
     cursor: pointer;
     width: 6rem;
     height: 6rem;
   
   }
   .home-social-link:hover{
     text-decoration: none !important;
   }
                                       
   .home-social-icon{
     width: 100%;
     /* color: #fff; */
   }
   .custom_footer_first{
       display: flex;
       justify-content: space-around;
   }
   /*      SOCIAL MEDIA LINKS ENDS    */


   @media only screen and (max-width: 767.9px) {
    .custom_footer_heading{
      padding-block: 3rem 0rem;
    }

    .footer_app_download_links {
      text-align: center;
    }

    .footer_app_download_links img {
      margin-right: 0%;
      /* display: block; */
  }
  .footer_app_download_container{
    display: none;
  }
  .footer_links_container{
    padding-block: 5rem 3rem;
}

  
  }

   @media only screen and (max-width: 600.9px) {
  
    .home-social-link{
      padding: 4px;
      width: 4rem;
      height: 4rem;
    
    }
    .footer_app_download_links img {
      width: 25%;
  }
  }

 