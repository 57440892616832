.discover_features{
    padding: 13% 3%;
    background-color: var(--secondary);
    width: 100%;
    
}

.discover_features img{
    max-width: 100%;
}
.discover_section_left{
    padding-right: 6%;
    align-items: center;
    padding-block: 5%;
}

.discover_section_right{
    padding-left: 6%;
    align-items: center;
    padding-block: 5%;
}

@media only screen and (max-width: 767.9px) {    
    .bottom_line{
        border-bottom: 1px solid rgb(110, 108, 108);
    }

    .discover_section_left{
        padding-inline: 10%;
        padding-block: 7%;
    }
    
    .discover_section_right{
    padding-inline: 10%;
        padding-block: 7%;
    flex-direction: column-reverse !important;
    }
  }