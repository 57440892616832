@import url(https://use.typekit.net/gcd1tcd.css);
.home_header{
    /* display: flex;
    align-items: center; */
    margin-top: 3%;
    width: 100%;
    padding:3% 2%;
    font-family: myriad-arabic !important;
}

.home_header_content h2{
    color: var(--primary);
    font-size: 7.5rem;
}

.home_header_content p{
    color: var(--text_color);
    font-size: 5rem;
    line-height: 7rem;
    padding-block: 4% 5%;
}

.home_header_image img{
    max-width: 100%;
}

.home_header_content{
    margin-top: 3% !important;
    
}

@media only screen and (max-width: 767.9px) {
    .home_header{
        /* display: flex;
        align-items: center; */
        padding-top: 80%;
    }

    .home_header_content{
        margin-top: 0% !important;
        text-align: center;
    }

    .home_header_content h2{
        font-size: 6rem;
    }
    
    .home_header_content p{
        font-size: 4rem;
    }
    
  }

  
@media only screen and (max-width: 400px) {

    .home_header_content h2{
        font-size: 5.5rem;
    }
    
    .home_header_content p{
        font-size: 3.5rem;
    }
    .home_header{
        /* display: flex;
        align-items: center; */
        padding-top: 80%;
    }
    
  }
.home{
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.home_header_container{
    background-color: var(--secondary);
    padding-bottom: 20%;

    background: url(/static/media/header.7ea79de6.png), var(--secondary);
    background-position: right -7% bottom 50%;
     background-size: 55% 75%;
     background-repeat: no-repeat;
}

@media only screen and (max-width: 991.9px) {
    .home_header_container{
         background-size: 60% 65%;
    }
  }

  @media only screen and (max-width: 767.9px) {
    .home_header_container{
        background-position: right 0% top 20%;
        background-size: 70% 40%;
        /* padding-bottom: 100%; */
   }
  }

  @media only screen and (max-width: 600px) {
    .home_header_container{
      background-position: right 0% top 20%;
      background-size: 95% 45%;;
    padding-bottom: 10%
    
        /* padding-bottom: 100%; */
   }
  }

  @media only screen and (max-width: 400px) {
    .home_header_container{
      background-position: right 0% top 20%;
      background-size: 95% 50%;
    padding-bottom: 2%;

    
        /* padding-bottom: 100%; */
   }
  }
.custom_navbar{
    font-size: 4rem;
    font-family: myriad-arabic !important;

    font-weight: 400;
    /* color: #fff !important; */
}

.custom_navbar_container{
    align-items: flex-start !important;
}

.custom_navbar .navbar-nav .nav-link{
    color: var(--primary) !important;
    margin: 0 5rem !important;

}
.custom_navbar .navbar-collapse{
    padding-top: 1.2%;
    padding-left: 10%;
}
.custom_navbar .home_logo{
    width: 15rem;
    margin-top: 4%;
    margin-left: 10%;
}

.custom_navbar .navbar-toggler{
    font-size: 3rem !important;
}

@media only screen and (max-width: 991px) {
    .custom_navbar .navbar-toggler{
        margin: 2%;
    }

    .custom_navbar .navbar-nav{
        
    flex-direction: row;
   
    justify-content: center;
    }
    .custom_navbar .navbar-collapse {
        padding-top: 1.2%;
        padding-left: 0%;
    }

    .custom_navbar .navbar-nav .nav-link {
        margin: 0 2rem !important;
    }

    /* .custom_navbar .home_logo{
        width: 70%;
       
    } */
  }

  @media only screen and (max-width: 767px) {
    .custom_navbar .home_logo{
        width: 10rem;
    }

    .custom_navbar .navbar-toggler{
        /* font-size: 3rem !important; */
        margin-top: 2rem;
        display: none;
    }

    .custom_navbar .navbar-collapse {
        /* position: absolute;
        top: 11rem; */
        width: 100%;
    }
    

    /* .custom_navbar .home_logo{
        width: 50%;
       
    } */
  }

  @media only screen and (max-width: 420px) {

    .custom_navbar .navbar-collapse {
        padding-top: 0%;
    }
    .custom_navbar .home_logo{
        width: 7rem;
    }

}

.app_download {
  padding: 5%;
  background-color: #fff;
  width: 100%;
  font-family: Arial;
}

.app_download_heading {
  color: var(--blue);
  font-size: 3.6rem;
  font-weight: bold;
  padding-bottom: 4%;
}
.app_download_content {
  padding-top: 5%;
}
.app_download .app_download_text {
  font-size: 3rem;
  color: #000;
}

.app_download img {
  max-width: 50%;
  width: 40%;
  cursor: pointer;
  /* display: block; */
}

.app_download_links{
  width: 100%;
  text-align: center;
  display: block;
}


@media only screen and (max-width: 767px){
  .app_download_content_wrapper{
    text-align: center;
  }
}
@media only screen and (max-width: 500px){
  .app_download {
    padding-block: 10%;
  }
  .app_download h2{
    padding-bottom: 5%;
  }
}

.discover_features{
    padding: 13% 3%;
    background-color: var(--secondary);
    width: 100%;
    
}

.discover_features img{
    max-width: 100%;
}
.discover_section_left{
    padding-right: 6%;
    align-items: center;
    padding-block: 5%;
}

.discover_section_right{
    padding-left: 6%;
    align-items: center;
    padding-block: 5%;
}

@media only screen and (max-width: 767.9px) {    
    .bottom_line{
        border-bottom: 1px solid rgb(110, 108, 108);
    }

    .discover_section_left{
        padding-inline: 10%;
        padding-block: 7%;
    }
    
    .discover_section_right{
    padding-inline: 10%;
        padding-block: 7%;
    flex-direction: column-reverse !important;
    }
  }
.primary_heading{
    font-size: 5rem;
    color: var(--primary);
    /* padding-bottom: 2%; */
    font-weight: bold;
}
.contactus{
    padding: 6% 4% 4% 4%;
    background-color: #fff;
    width: 100%;
}

.contactus h2{
    padding-bottom: 5%;
}
.contactus_section{
    padding-block: 5%;
    align-items: center;
}

.contactus_inputs_section{
    padding-left: 5% !important;
}


@media only screen and (max-width: 767.9px) {    
    .contactus_section{
        padding-inline: 8%;
    }

    .contactus_inputs_section {
        padding-left: 0% !important;
    }
    .contactus{
        padding: 12% 4% 4% 4%;
        background-color: #fff;
        width: 100%;
    }
  }
 .custom_input_container{
     margin-bottom: 5%;
 }
 .custom_input{
    padding: 1.5% 5%;
    font-size: 3.5rem;
    border: 1px solid #707070;
    width: 100%;
    border-radius: 6px !important;
    /* margin-bottom: 1rem; */

}

.custom_input::-webkit-input-placeholder{
    color: #A79291;

}

.custom_input:-ms-input-placeholder{
    color: #A79291;

}

.custom_input::placeholder{
    color: #A79291;

}

.custom_input:focus{
    outline: none;
    border: 1px solid #707070;;
}

.custom_input_error{
    font-size: 3rem;
    color: red;
}
.custom_button{
    font-size: 5rem !important;
    background-color: var(--blue) !important;
    outline: none !important;
    border: none !important;
    padding: 1% 7% !important;
    border-radius: 10px !important;
}

.custom_button .spinner-border{
    width: 5rem !important;
height: 5rem !important;
}

@media only screen and (max-width: 767.9px) {
    .custom_button{
        font-size: 4rem !important;
    }
    
    
  }
.custom_footer{
    background-color: var(--secondary);
    font-size: 3rem;
    /* font-size: 2.2rem; */
    color: var(--primary);
    font-family: fontAwsome5Brands !important;
}

.custom_footer_heading{
    text-align: center;
}

.custom_footer_copyrights{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 2%; */
    grid-gap: 2.5%;
    gap: 2.5%;
    flex-wrap: wrap;
    padding-bottom: 2rem;
}


  
  .footer_app_download_links{
      display: inline-block;
      /* width: 50rem; */
    text-align: end;
   
  }

  .footer_app_download_links img {
    max-width: 50%;
    width: 20%;
    cursor: pointer;
    margin-right: 15%;
    /* display: block; */
  }
.footer_links_container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-block: 5rem;
}
.footer_app_download_container{
    text-align: right;
    display: flex;
    flex-direction: column;
}
  /*      SOCIAL MEDIA LINKS    */
.home-social-links{
  display: flex;
  justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    /* padding-bottom: 8rem; */
   }

   .falcon_footer a{
       color: #f87873 !important;
       font-weight: bold;
       display: inline-block;
       text-decoration: none !important;
       
   }
   .home-social-link{
     /* margin-left: -0rem; */
     display: flex;
     background-color: var(--primary);
     padding: 1.5rem;
     border-radius: 50%;
     -webkit-filter: drop-shadow(0 0 1rem rgba(0,0,0,0.3));
             filter: drop-shadow(0 0 1rem rgba(0,0,0,0.3));
     color: #fff !important;
     cursor: pointer;
     width: 6rem;
     height: 6rem;
   
   }
   .home-social-link:hover{
     text-decoration: none !important;
   }
                                       
   .home-social-icon{
     width: 100%;
     /* color: #fff; */
   }
   .custom_footer_first{
       display: flex;
       justify-content: space-around;
   }
   /*      SOCIAL MEDIA LINKS ENDS    */


   @media only screen and (max-width: 767.9px) {
    .custom_footer_heading{
      padding-block: 3rem 0rem;
    }

    .footer_app_download_links {
      text-align: center;
    }

    .footer_app_download_links img {
      margin-right: 0%;
      /* display: block; */
  }
  .footer_app_download_container{
    display: none;
  }
  .footer_links_container{
    padding-block: 5rem 3rem;
}

  
  }

   @media only screen and (max-width: 600.9px) {
  
    .home-social-link{
      padding: 4px;
      width: 4rem;
      height: 4rem;
    
    }
    .footer_app_download_links img {
      width: 25%;
  }
  }

 
.custom_modal.modal {
    top:50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    height: 100%;
}

.custom_modal .modal-dialog{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 2rem !important;
}
.custom_modal .modal-body{
    padding: 0 !important;
}
.modal_header{
    background: var(--secondary);
    width: 100%;
    padding: 2rem;
    
}
.model_heading{
    font-size: 5rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    font-weight: bold;
    color: var(--primary);
}

.model_heading img{
    width: 8rem;
}

.custom_modal_body{
    display: flex;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    padding-block: 8rem;
}

.custom_modal_btn{
    background: var(--blue);
    color: #fff;
    display: inline-block;
    padding: 1% 2%;
    border-radius: 1rem;
    width: 10rem;
    min-width: 20rem;
    text-align: center;
    text-decoration: none !important;
    cursor:pointer;
    font-size: 3rem;
    
}

.custom_modal .modal-content{
border: none !important;
width: 50%;
}

.custom_modal_btn:hover{
    color: #fff !important;
}


.custom_modal .close_btn{
    background: var(--primary);
}



@media only screen and (max-width: 991.9px) {
    .custom_modal .modal-content{
        border: none !important;
        width: 80%;
        }
        
  }

  @media only screen and (max-width: 500px) {
    .custom_modal_body{
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem;
        padding-block: 8rem;
        align-items: center;
    }
    
  }

/* import all css files */


/* Import Fonts */
@font-face{
  font-family: myriad-arabic;
  src: url(/static/media/MYRIADPRO-BOLD.1c643226.woff);
  src: url(/static/media/MYRIADPRO-COND.26dcc00c.woff);
  src: url(/static/media/MyriadPro-Light.43b72dcd.woff);
  src: url(/static/media/MYRIADPRO-SEMIBOLD.230dfc3b.woff);
  src: url(/static/media/MYRIADPRO-REGULAR.d30b0546.woff);
}

@font-face{
  font-family: fontAwsome5Brands;
  src: url(/static/media/fa-brands-400.7592ba4b.ttf);
}


/* Colors   */
:root {
  --primary: #F78985;
  --secondary: #FFF5F3;
  --text_color: #100F0F;
  --blue: #A485F7;
}

/*  Scroll Bar  */
body::-webkit-scrollbar {
  width: 1em;
  border-radius: 1rem;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #F78985;
  background-color: var(--primary);
  outline: 1px solid slategrey;
  border-radius: 5rem;

}


*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p{
  line-height: 1.3 !important;
}

html{
  font-size: 40%;
  line-height: 1.3;
  scroll-behavior: smooth;
}

body{
  font-family: Arial;
  }
  

  .secondary_heading{
    font-size: 4.5rem;
    font-weight: bold;
    color: #F78985;
    color: var(--primary);
    padding-bottom: 2rem;
  }

  .primary_text{
    color: #000;
    font-size: 4rem;
    line-height: 1.2;
  }

  @media only screen and (max-width: 1600px) {
    html{
      font-size: 30%;
    }
  }

  @media only screen and (max-width: 1366.9px) {
    html{
      font-size:30%;
    }
  }

  @media only screen and (max-width: 991.9px) {
    html{
      font-size:28%;
    }
  }

  @media only screen and (max-width: 767.9px) {
    html{
      font-size:27%;
    }
  }

  @media only screen and (max-width: 500.9px) {
    html{
      font-size:25%;
    }
  }

  @media only screen and (max-width: 420.9px) {
    html{
      font-size:20%;
    }
  }
