.custom_navbar{
    font-size: 4rem;
    font-family: myriad-arabic !important;

    font-weight: 400;
    /* color: #fff !important; */
}

.custom_navbar_container{
    align-items: flex-start !important;
}

.custom_navbar .navbar-nav .nav-link{
    color: var(--primary) !important;
    margin: 0 5rem !important;

}
.custom_navbar .navbar-collapse{
    padding-top: 1.2%;
    padding-left: 10%;
}
.custom_navbar .home_logo{
    width: 15rem;
    margin-top: 4%;
    margin-left: 10%;
}

.custom_navbar .navbar-toggler{
    font-size: 3rem !important;
}

@media only screen and (max-width: 991px) {
    .custom_navbar .navbar-toggler{
        margin: 2%;
    }

    .custom_navbar .navbar-nav{
        
    flex-direction: row;
   
    justify-content: center;
    }
    .custom_navbar .navbar-collapse {
        padding-top: 1.2%;
        padding-left: 0%;
    }

    .custom_navbar .navbar-nav .nav-link {
        margin: 0 2rem !important;
    }

    /* .custom_navbar .home_logo{
        width: 70%;
       
    } */
  }

  @media only screen and (max-width: 767px) {
    .custom_navbar .home_logo{
        width: 10rem;
    }

    .custom_navbar .navbar-toggler{
        /* font-size: 3rem !important; */
        margin-top: 2rem;
        display: none;
    }

    .custom_navbar .navbar-collapse {
        /* position: absolute;
        top: 11rem; */
        width: 100%;
    }
    

    /* .custom_navbar .home_logo{
        width: 50%;
       
    } */
  }

  @media only screen and (max-width: 420px) {

    .custom_navbar .navbar-collapse {
        padding-top: 0%;
    }
    .custom_navbar .home_logo{
        width: 7rem;
    }

}
